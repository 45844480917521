<template>
  <div>
    <a-card>
      <div class="table-operator">
        <a-form layout="inline">
          <a-row :gutter="0" style="line-height: 3">
            <a-col :md="7" :sm="24">
              <a-form-item label="门店名称">
                <a-input
                  v-model="queryParam.name"
                  placeholder="请输入"
                  @pressEnter="select"
                />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="店铺名称">
                <a-input
                  v-model="queryParam.code"
                  placeholder="请输入"
                  @pressEnter="select"
                />
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-item label="退租方式">
                <a-select
                  style="width: 175px"
                  v-model="queryParam.status"
                  @change="select"
                  allowClear
                  placeholder="请选择"
                  default-value="0"
                >
                  <a-select-option v-for="item in 5" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :md="(!advanced && 3) || 24"
              :sm="24"
              style="text-align: right"
            >
              <span
                class="table-page-search-submitButtons"
                :style="
                  (advanced && { float: 'right', overflow: 'hidden' }) || {}
                "
              >
                <a-button
                  style="margin-left: 8px"
                  @click="() => ((queryParam = {}), this.init())"
                  >重置</a-button
                >
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator" style="margin-top: -8px">
        <a-button @click="financePayNew">发给店长</a-button>
        <a-button type="primary" icon="plus" @click="financePayNew"
          >发送账单</a-button
        >
      </div>
      <a-table
        rowKey="id"
        :pagination="pagination"
        :columns="columns"
        :dataSource="data"
        :rowSelection="rowSelection"
        style="margin-bottom: 24px"
      >
        <!--      <a slot="name" slot-scope="text" href="javascript:;">{{text}}</a>-->
        <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
        <span slot="action" slot-scope="text, record" class="blueColor">
          <a @click="financePayConfirm(record)">调整</a>
        </span>
      </a-table>
    </a-card>
    <a-modal
      title="新增"
      :width="640"
      :visible="finPayNew"
      :confirmLoading="confirmLoading"
      destroyOnClose
      @ok="handleNew"
      @cancel="handleCancel"
    >
      <a-spin :spinning="confirmLoading">
        <a-form :form="form">
          <a-form-item
            label="门店名称"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-select
              v-decorator="[
                'facilityId',
                { rules: [{ required: true, message: '请输入' }] }
              ]"
            >
              <a-select-option v-for="item in storeList" :key="item.id">
                {{ item.optionText }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="公司主体名称"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input
              v-decorator="[
                'companyEntityName',
                { rules: [{ required: true, message: '请输入' }] }
              ]"
            />
          </a-form-item>
          <a-form-item
            label="银联子客户号"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input
              v-decorator="[
                'mid',
                { rules: [{ required: true, message: '请输入' }] }
              ]"
            />
          </a-form-item>
          <a-form-item
            label="终端号"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input
              v-decorator="[
                'tid',
                { rules: [{ required: true, message: '请输入' }] }
              ]"
            />
          </a-form-item>
          <a-form-item
            label="支付类型"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-select
              v-decorator="[
                'useType',
                { rules: [{ required: true, message: '请输入' }] }
              ]"
            >
              <a-select-option v-for="item in typeList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
    <a-modal
      title="调整"
      :width="640"
      :visible="finPayConfirm"
      :confirmLoading="confirmLoading"
      destroyOnClose
      @ok="handleConfirm"
      @cancel="handleCancel"
    >
      <a-spin :spinning="confirmLoading">
        <a-form :form="form">
          <a-form-item
            label="质量保证金"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input
              v-decorator="[
                'test',
                {
                  rules: [{ required: true, message: '请输入' }]
                  // initialValue: this.record.companyEntityName
                }
              ]"
              placeholder="请输入"
            />
          </a-form-item>
          <a-form-item
            label="燃气押金"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input
              v-decorator="[
                'companyEntityName',
                {
                  rules: [{ required: true, message: '请输入' }],
                  initialValue: this.record.companyEntityName
                }
              ]"
              placeholder="请输入"
            />
          </a-form-item>
          <a-form-item
            label="房租"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input
              v-decorator="[
                'mid',
                {
                  rules: [{ required: true, message: '请输入' }],
                  initialValue: this.record.mid
                }
              ]"
              placeholder="请输入"
            />
          </a-form-item>
          <a-form-item
            label="离场清洁费"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input
              v-decorator="[
                'tid',
                {
                  rules: [{ required: true, message: '请输入' }],
                  initialValue: this.record.tid
                }
              ]"
            />
          </a-form-item>
          <a-form-item
            label="离场工程费"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-input
              v-decorator="[
                'name',
                { rules: [{ required: true, message: '请输入' }] }
              ]"
              placeholder="请输入"
            />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
  

import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  }
};
export default {
  name: "finSettlement",
  data() {
    return {
      rowSelection,
      confirmLoading: false,
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      data: [
        {
          id: "1",
          test1: "123",
          test2: "不清楚"
        }
      ],
      // payType:'',
      typeList: [
        { id: 1, name: "APP支付" },
        { id: 2, name: "C扫B支付" },
        { id: 3, name: "POS支付" }
      ],
      queryParam: {
        name: "",
        code: "",
        status: ""
      },
      pagination: defaultPagination(() => {}),
      // 表头
      columns: [
        {
          title: "门店名称",
          dataIndex: "test1",
          key: "test1",
          fixed: "left"
        },
        {
          title: "档口编号",
          dataIndex: "test2",
          key: "test2"
        },
        {
          title: "店铺名称",
          dataIndex: "test3",
          key: "test3"
        },
        {
          title: "合同编号",
          dataIndex: "test4",
          key: "test4"
        },
        {
          title: "合同附件",
          dataIndex: "test5",
          key: "test5"
        },
        {
          title: "退租方式",
          dataIndex: "test6",
          key: "test6"
        },
        {
          title: "离场日期",
          dataIndex: "test7",
          key: "test7"
        },
        {
          title: "质量保证金",
          dataIndex: "test8",
          key: "test8"
        },
        {
          title: "燃气押金",
          dataIndex: "test9",
          key: "test9"
        },
        {
          title: "房租",
          dataIndex: "test10",
          key: "test10"
        },
        {
          title: "离场清洁费",
          dataIndex: "test11",
          key: "test11"
        },
        {
          title: "离场工程费",
          dataIndex: "test12",
          key: "test12"
        },
        {
          title: "公摊费",
          dataIndex: "test13",
          key: "test13"
        },
        {
          title: "其他账单费用",
          dataIndex: "test14",
          key: "test14"
        },
        {
          title: "总计",
          dataIndex: "total",
          key: "total"
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" }
        }
      ],
      finPayNew: false,
      finPayConfirm: false,
      advanced: false,
      record: "",
      // 接口来的门店数据
      storeList: []
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  methods: {
    changePageSize(val) {
      this.init(val.current, val.pageSize);
    },
    // 门店筛选接口
    // selectStores() {
    //   axios({
    //     url:   this.$baseUrl + "",
    //     method: "GET"
    //   }).then(res => {
    //     if (res.data.rows) {
    //       this.storeList = res.data.rows;
    //     }
    //   });
    // },
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1
          // entityName: this.queryParam.entityName
        }
      }).then(res => {
        if (res.data.rows) {
          this.data = res.data.rows;
          // this.data.forEach(item => {
          //   this.$set(item, "payType", "");
          //   if (item.useType == 1) {
          //     item.payType = "APP支付";
          //   } else if (item.useType == 2) {
          //     item.payType = "C扫B支付";
          //   } else if (item.useType == 3) {
          //     item.payType = "POS支付";
          //   }
          // });
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      });
    },
    select() {
      this.init();
    },
    financePayNew() {
      // this.finPayNew = true;
    },
    financePayConfirm(val) {
      this.record = val;
      console.log(this.record);
      this.finPayConfirm = true;
    },
    handleNew() {
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          this.addandedit(values);
          setTimeout(() => {
            this.finPayNew = false;
            this.confirmLoading = false;
            this.$emit("ok", values);
          }, 1500);
        } else {
          this.confirmLoading = false;
        }
      });
    },
    addandedit(val) {
      axios({
        url: "",
        method: "POST",
        data: {
          ...val
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.$message.success("新建成功！");
          this.init();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        console.log(res.data);
      });
    },
    handleConfirm() {
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          that.unionPayEdit(values);
          setTimeout(() => {
            this.finPayConfirm = false;
            this.confirmLoading = false;
            this.$emit("ok", values);
          }, 1500);
        } else {
          this.confirmLoading = false;
        }
      });
      // this.finPayConfirm = false;
    },
    unionPayEdit(values) {
      axios({
        url:   this.$baseUrl + "",
        method: "POST",
        data: {
          ...values,
          // facilityId: this.record.id,
          id: this.record.id
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.init();
        }
      });
    },
    handleCancel() {
      this.init();
      this.finPayNew = false;
      this.finPayConfirm = false;
    }
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 18px;
}
</style>
